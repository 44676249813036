import React from "react";

const EducationPage: React.FC = () => {
  return (
    <div className="h-100">
      <h1 className="coming-soon">Coming soon...</h1>
    </div>
  );
};

export default EducationPage;
